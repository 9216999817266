import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, adminColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../utils/config";
import Cookies from "js-cookie";
import csvInit from "../../utils/csvManager";

import Modal from "react-modal";
Modal.setAppElement('#root');

const Datatable = () => {

  const [data, setData] = useState([]);

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const accessToken = Cookies.get("adminToken");

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    name: '',
    tableData: []
  });

  const handleOpenModal = (leads, name) => {
    const tabledata = leads.map((m) => {
      return {
        'Name': m.name !== '' ? m.name : '-',
        'Email': m.email !== '' ? m.email : '-',
        'Phone': m.phone !== '' ? m.phone : '-',
        'Note': m.note !== '' ? m.note : '-',
      }
    })

    setModalData({
      name: name,
      tableData: tabledata
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setModalData({
      name: '',
      tableData: []
    });
    setShowModal(false)
  };

  const getHeadings = (data) => {
    return Object.keys(data[0] ?? []);
  }

  useEffect(() => {
    const checkAuth = async () => {
      const admin = await axios.get(`${baseUrl}/check-auth`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (admin.data.data.role == 'superadmin') setIsSuperAdmin(true);
    };

    const fetchData = async () => {
      const response = await axios.get(`${baseUrl}/get-all-users`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      // add id to each object
      response.data.map((item, index) => {
        item.id = index + 1;
        item.contactsNumber = item.contacts.length;
      });

      setData(response.data);
    };

    checkAuth();
    fetchData();
  }, []);


  const handleDelete = async (id, mongo_id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      const response = await axios.delete(`${baseUrl}/delete-user/${mongo_id}`);
      if (response.status === 200) {
        setData(data.filter((item) => item.id !== id));
        alert("User deleted successfully");
      }
    }
  };

  const downloadCSV = () => csvInit(getHeadings(modalData.tableData), modalData.tableData, modalData.name);

  const downloadAllCSV = () => {
    let leads = [];
    data.forEach((sa) => {
      const contacts = sa.contacts.map((m) => {
        return {
          'Name': m.name ?? '-',
          'Email': m.email ?? '-',
          'Phone': m.phone ?? '-',
          'Note': m.note ?? '-',
        }
      });
      leads = [...leads, ...contacts];
    });
    csvInit(getHeadings(leads), leads);
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link target="_blank" to={`/user/${params.row._id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            <div onClick={() => handleOpenModal(params.row.contacts, params.row.name)} style={{ textDecoration: "none" }}>
              <div className="viewButton">View Leads</div>
            </div>
            {
              isSuperAdmin && (
                <div className="deleteButton" onClick={() => handleDelete(params.row.id, params.row._id)}>
                  Delete
                </div>
              )
            }
            {
              isSuperAdmin && (
                <Link to={`/user/update/${params.row._id}`} style={{ textDecoration: "none" }}>
                  <div className="viewButton">Update</div>
                </Link>
              )
            }
          </div>
        );
      },
    },
  ];


  return (
    <div className="datatable">
      <div className="datatableTitle">
        {isSuperAdmin && 'Add New User'}
        <div style={{ display: 'flex', gap: '10px' }}>
          {isSuperAdmin &&
            <Link to={"/users/new"} className="link"> Add New </Link>
          }
          <button style={{ backgroundColor: "transparent" }} onClick={downloadAllCSV} className="link">
            Donwload All Leads
          </button>
        </div>
      </div>

      <DataGrid
        getRowHeight={() => 120}
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />

      <Modal isOpen={showModal} onRequestClose={handleCloseModal} className="leadModal">
        <div style={{ display: 'flex', justifyContent: "space-between", width: '100%', alignItems: 'center', marginBottom: '10px' }}>
          <h1 className="modalTitle"> Leads </h1>
          <div style={{ display: 'flex', justifyContent: "center", gap: '10px', alignItems: 'center' }}>
            {
              modalData?.tableData?.length > 0
              &&
              <div className="deleteButton" onClick={downloadCSV}>
                Download CSV
              </div>
            }
            <span className="close-btn" onClick={handleCloseModal}>  &#10005; </span>
          </div>
        </div>
        {modalData?.tableData?.length > 0 && <Table theadData={getHeadings(modalData.tableData)} tbodyData={modalData.tableData} />}
      </Modal>
    </div>
  );
};


function Table({ theadData, tbodyData }) {
  return (
    <div className="leadTableContainer">
      <table className="leadTable" >
        <thead>
          <tr>
            {theadData.map(heading => {
              return <th>{heading}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {tbodyData.map((row, index) => {
            return <tr key={index}>
              {theadData.map((key, index) => {
                return <td key={row[key]}>{row[key]}</td>
              })}
            </tr>;
          })}
        </tbody>
      </table>
    </div>
  );
}
export default Datatable;
