import "./chart.scss";
import ApexCharts from 'apexcharts'
import ReactApexChart from "react-apexcharts";

import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../utils/config";
import Cookies from "js-cookie"

const Chart = ({ title, analyticsType }) => {

  const accessToken = Cookies.get("adminToken");

  const [data, setData] = useState([]);
  const [firstData, seFirsttData] = useState([]);
  const [lastData, setLastData] = useState([]);
  const [selection, setSelection] = useState("one_year");

  const state = {
    options: {
      colors: ['#6439ff'],
      chart: {
        id: title,
        type: 'area',
        height: 350,
        zoom: {
          autoScaleYaxis: true
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
        style: 'hollow',
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${baseUrl}/analytics/${analyticsType}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setData(response.data);
      seFirsttData(response.data[0]);
      setLastData(response.data[response.data.length - 1]);
    };
    fetchData();
  }, []);

  const addMonths = (date, months) => {
    // Copy the original date to avoid modifying it directly
    const newDate = new Date(date);
    
    // Get the current month and year
    let currentMonth = newDate.getMonth();
    let currentYear = newDate.getFullYear();
    
    // Add the specified number of months
    currentMonth += months;
    
    // Adjust the year if necessary
    while (currentMonth >= 12) {
      currentMonth -= 12;
      currentYear++;
    }
    
    // Set the new month and year
    newDate.setMonth(currentMonth);
    newDate.setFullYear(currentYear);

    return newDate;
  }

  const updateData = (timeline) => {
    setSelection(timeline);

    switch (timeline) {
      case 'one_month':
        ApexCharts.exec(
          title,
          'zoomX',
          new Date(firstData[0]).getTime(),
          addMonths(firstData[0],1).getTime()
        )
        break
      case 'six_months':
        ApexCharts.exec(
          title,
          'zoomX',
          new Date(firstData[0]).getTime(),
          addMonths(firstData[0],6).getTime()
        )
        break
      case 'one_year':
        ApexCharts.exec(
          title,
          'zoomX',
          new Date(firstData[0]).getTime(),
          addMonths(firstData[0],12).getTime()
        )
        break
      case 'all':
        ApexCharts.exec(
          title,
          'zoomX',
          new Date(firstData[0]).getTime(),
          new Date(lastData[0]).getTime()
        )
        break
      default:
    }
  }


  return (
    <div id="chart">
      <div className="title">{title}</div>
      <div className="toolbar">
        <button id="one_month"
          onClick={() => updateData('one_month')} className={(selection === 'one_month' ? 'active' : '')}>
          1M
        </button>
        &nbsp;
        <button id="six_months"
          onClick={() => updateData('six_months')} className={(selection === 'six_months' ? 'active' : '')}>
          6M
        </button>
        &nbsp;
        <button id="one_year"
          onClick={() => updateData('one_year')} className={(selection === 'one_year' ? 'active' : '')}>
          1Y
        </button>
        &nbsp;
        <button id="all"
          onClick={() => updateData('all')} className={(selection === 'all' ? 'active' : '')}>
          ALL
        </button>
      </div>
      <div id="chart-timeline">
        <ReactApexChart options={state.options} series={[{ name: title, data }]} type="area" height={350} />
      </div>
    </div>
  );
}


export default Chart;
