import React from 'react'
import './loading.scss'


const Loading = () => {
    return (
        <div className="main-container">
            <img className='loading-image' src='http://digitalmu-nfc.com/static/media/TanyajeLogoA.89d62f2e6b5e884e4b0b.png' />
            <img className='loading-image-bg' src='http://digitalmu-nfc.com/static/media/Group 176687.c776c44fee2f47812452.png' />
        </div>
    )
}

export default Loading