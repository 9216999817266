import React, { useEffect, useState } from 'react'
import muLogo from "../../images/muLogo.png"
import image from "../../images/Group 177224.png"
import CloseIcon from '@mui/icons-material/Close';
import Helmet from 'react-helmet';
import './exchangeContactConfrimation.scss'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import baseUrl from '../../utils/config.js';

const ExchangeContactConfrimation = () => {
    const navigate = useNavigate()
    const urlParams = new URLSearchParams(window.location.search);
    const urlId = urlParams.get("userId");
    const [redirect, setRedirect] = useState("/user/" + urlId);
    const [external, setExternal] = useState(false);

    useEffect(() => {
        const first = async () => {
            await axios.get(`${baseUrl}/get-user?userId=${urlId}`)
            .then(response => {

                if(response?.data?.addContactReroute){
                    setRedirect(response.data.addContactReroute);
                    setExternal(true);
                }

            }).catch(error => console.log(error));
        }
        first();
    }, [urlId])


    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, user-scalable=no" />
            </Helmet>
            <div className='exchange-contact-container'>
                <div className="navbar">
                    <div style={{ margin: "0px 20px" }} >  </div>
                    <div className="close-btn">
                        <div className="exchange-contact-top-div-close-btn" onClick={() => navigate(-1)} >
                            <CloseIcon />
                        </div>
                    </div>
                </div>
                <div className="main-container">
                    <img src={muLogo} alt="logo" className='muLogo' />
                    <img src={image} alt="logo" className='exchange-image' />
                    <div className="exchange-contact-middle-div">
                        Thank you for exchanging your contact information!
                    </div>
                </div>
                <div className="bottom-container">
                    {
                        external ? ( <a href={redirect}> Continue </a> ) : 
                        (<button onClick={() => navigate(redirect)} type='submit'>
                            Continue
                        </button>)
                    }
                    
                </div>
            </div >
        </>
    )
}

export default ExchangeContactConfrimation