import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Cookies from "js-cookie";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Chart from "../../components/chart/Chart";
import "../../components/datatable/datatable.scss";
import Datatable from "../../components/datatable/Datatable"
import { userColumns2, userColumns, userRows } from "../../datatablesource";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import baseUrl from "../../utils/config";

// import List from "../list/List";
const Home = (props) => {
  const navigate = useNavigate();
  const accessToken = Cookies.get("adminToken");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${baseUrl}/check-auth`, {
          headers: {
            // get token from cookies access token
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setIsAuthenticated(true);
        setIsAuthenticating(false);

      } catch (error) {
        console.log(error);
        setIsAuthenticated(false);
        setIsAuthenticating(false); 
        navigate("/admin-login");
      }

    };
    checkAuth();
  }, []);



  return (
    <>
      {isAuthenticating ? (
        <div>Loading...</div>
      ) : isAuthenticated ? (

        <div className="home">
          {isAuthenticated && <>
            <Sidebar />
            <div className="homeContainer">
              <Navbar />
              {props.page !== "stats" &&
                <div className="widgets">
                  <Widget type="allUser" />
                  <Widget type="activeUser" />
                  <Widget type="inActiveUser" />
                </div>
              }
              {props.page === "stats" && (<>
                <div className="gcharts" style={{ paddingTop: 20 }}>
                  <Chart title="New Users / Day" analyticsType="users" aspect={3 / 1} /> 
                  <Chart title="New Contacts / Day" analyticsType="contacts" aspect={3 / 1} /> 
                  <Datatable columnsName={userColumns} />
                </div>
              </>)}


              {props.page !== "stats" && <Datatable columnsName={userColumns} />}

            </div>
          </>
          }
        </div >
      ) : (
        <div>Not authenticated</div>
      )}
    </>
  );
};

export default Home;
