import React, { useEffect, useState } from 'react'
import muLogo from "../../images/contactExchange.png"
import CloseIcon from '@mui/icons-material/Close';
import Helmet from 'react-helmet';
import './exchangeContact.scss'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../utils/config.js';
import Loading from '../loading/loading';

const ExchangeContact = () => {
    const navigate = useNavigate()
    const url = window.location.href;
    const urlParams = new URLSearchParams(window.location.search);
    const urlId = urlParams.get("userId");
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const first = async () => {
            await axios
                .get(`${baseUrl}/get-user?userId=${urlId}`)
                .then(response => {
                    response.data.date = response.data.date.substring(0, 10);
                    setUser(response.data);
                    setIsLoading(false);

                }
                )
                .catch(error => console.log(error));
        }
        first();
    }, [urlId])


    const handleSubmit = (e) => {

        e.preventDefault();
        const name = e.target.name.value;
        const phone = e.target.phone.value;
        const email = e.target.email.value;
        const note = e.target.note.value;
        axios.post(`${baseUrl}/add-contact`, {
            userId: urlId,
            name,
            email: email ? email : '',
            note: note ?? '',
            phone
        }).then(response => {
            navigate('/exchange-contact-confirmation?userId=' + urlId);
        }).catch(error => {
            console.log(error)
            alert('Something went wrong')
        }
        )
    }

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, user-scalable=no" />
            </Helmet>
            {isLoading ? <Loading></Loading> :
                <div className='exchange-contact-container'>
                    <div className="navbar">
                        <div style={{ margin: "0px 20px" }} >  </div>
                        <p> Connected With me </p>
                        <div className="close-btn">
                            <div className="exchange-contact-top-div-close-btn" onClick={() => navigate(-1)} >
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className="main-container">
                        <img src={muLogo} alt="logo" />

                        <form onSubmit={handleSubmit}
                            className="exchange-contact-wrapper">
                            <div className="exchange-contact-top-div">
                                <div className="exchange-contact-top-div-text">
                                    Exchange Contact
                                </div>
                            </div>
                            <div className="exchange-contact-middle-div">
                                I would also like to get in touch with you.
                            </div>
                            <div className="input-wrapper">
                                <input type="text" placeholder='Name' id='name' required />
                            </div>
                            <div className="input-wrapper">
                                <input type="email" placeholder='Email (optional)' id='email' />
                            </div>
                            <div className="input-wrapper">
                                <input type="text" placeholder='Phone' id='phone' required />
                            </div>
                            <div className="input-wrapper">
                                <input maxlength="70" type="text" placeholder='Note (optional)' id='note' />
                            </div>
                            <div className="bottom-container">
                                <button
                                    style={{
                                        cursor: 'pointer'
                                    }}

                                    type='submit'
                                > Submit </button>
                            </div>
                        </form>
                    </div>

                </div >
            }
        </>
    )
}

export default ExchangeContact