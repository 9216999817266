import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { adminColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../../utils/config";
import Cookies from "js-cookie";

import Modal from "react-modal";
Modal.setAppElement('#root');

const DatatableAdmin = () => {

  const [adminData, setAdminData] = useState([]);

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const accessToken = Cookies.get("adminToken");

  useEffect(() => {
    const checkAuth = async () => {
      const admin = await axios.get(`${baseUrl}/check-auth`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (admin.data.data.role == 'superadmin') setIsSuperAdmin(true);
    };

    const fetchData = async () => {
      const response = await axios.get(`${baseUrl}/admins`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      // add id to each object
      response.data.map((item, index) => {
        item.id = index + 1;
      });

      setAdminData(response.data);
    };

    checkAuth();
    fetchData();
  }, []);


  const handleDeleteAdmin = async (id, mongo_id) => {
    if (window.confirm("Are you sure you want to delete this admin ?")) {
      const response = await axios.delete(`${baseUrl}/admin/${mongo_id}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.status === 200) {
        setAdminData(adminData.filter((item) => item.id !== id));
        alert("Admin deleted successfully");
      }
    }
  }

  const adminActionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div className="deleteButton" onClick={async () => handleDeleteAdmin(params.row.id, params.row._id)}>
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        {isSuperAdmin && 'Admins'}
        <div style={{ display: 'flex', gap: '10px' }}>
          {isSuperAdmin &&
            <Link to={"/admins/new"} className="link"> Add New </Link>
          }
        </div>
      </div>

      <DataGrid
        getRowHeight={() => 120}
        className="datagrid"
        rows={adminData}
        columns={adminColumns.concat(adminActionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />

    </div>
  );
};


export default DatatableAdmin;
